<template>
    <div class="create-release-layout">
        <projectIsUnderConstructionWarning></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";

export default {
    name: "create-release-layout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>

<style lang="scss">

</style>